import React from 'react'
import { Link } from 'react-router-dom'


const Paddy = () => {
    return (
        <>
            <div style={{ marginTop: '124px' }}></div>

            <div className='backGroundPaddy'>
                <div className='text-center'>
                    <div><h1 style={{ fontSize: '80px' }}><b>PADDY</b></h1></div>
                </div>
            </div>

            {/* <div className='container mt-5'>
                <h2 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI AVANTIKA</h2>
                <div className='row'>
                    <div className='col-md-6 col-sm-12 mt-3'>
                        <center>
                            <img src='01.jpg' alt='SINDHURI AVANTIKA' style={{ width: '30%' }}></img>
                        </center>
                    </div>
                    <div className='col-md-6 col-sm-12 mt-4'>
                        <p className='mb-4' style={{ textAlign: 'justify' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                        <Link className='KnowMoreLinks'>Know More</Link>
                    </div>
                </div>
            </div>
            <div className='container mt-5'>
                <h2 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI CHANDINI</h2>
                <div className='row'>
                   
                    <div className='col-md-6 col-sm-12 mt-4'>
                        <p className='mb-4' style={{ textAlign: 'justify' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                        <Link className='KnowMoreLinks'>Know More</Link>
                    </div>
                    <div className='col-md-6 col-sm-12 mt-3'>
                        <center>
                            <img src='09.jpg' alt='SINDHURI CHANDINI' style={{ width: '30%' }}></img>
                        </center>
                    </div>
                </div>
            </div>
            <div className='container mt-5'>
                <h2 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI SRILEKHA</h2>
                <div className='row'>
                    <div className='col-md-6 col-sm-12 mt-3'>
                        <center>
                            <img src='1010.jpg' alt='SINDHURI SRILEKHA' style={{ width: '30%' }}></img>
                        </center>
                    </div>
                    <div className='col-md-6 col-sm-12 mt-4'>
                        <p className='mb-4' style={{ textAlign: 'justify' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                        <Link className='KnowMoreLinks'>Know More</Link>
                    </div>
                </div>
            </div> */}
            <div className='container mt-5'>
            <h2 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>Paddy Products</h2>
                <div className='row mt-5'>
                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded' >
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='01.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI AVANTIKA</h3>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded' >
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='09.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI CHANDINI</h3>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded'>
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='1010.jpg' alt='images' className='imageZoom' style={{ width: '78%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI SRILEKHA</h3>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 P-5 mt-2'>
                        <div className='carded'>
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='WhatsApp Image 2024-02-06 at 11.31.07 AM (1)fdf.jpeg' alt='images' className='imageZoom' style={{ width: '78%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI LAKSHMI</h3>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 P-5 mt-2'>
                        <div className='carded'>
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='WhatsApp Image 2024-02-06 at 11.31.07 AMfrfg.jpeg' alt='images' className='imageZoom' style={{ width: '78%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI RASHIMIKA</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Paddy