import React from 'react'
import './FooterStyles.css'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <div className='Container-fluid mt-5' >
    <footer class="footer" style={{marginTop:'130px'}}>
    <div class="waves">
      <div class="wave" id="wave1"></div>
      <div class="wave" id="wave2"></div>
      <div class="wave" id="wave3"></div>
      <div class="wave" id="wave4"></div>
    </div>
    <div className='container'>
      <div className='row container' style={{color:'whitesmoke'}}>
        <div className='col-md-4 text-center'>        
          <img className='footerImage'  src='Untitled-2.png' ></img>
          <p className='mt-3'>At Sindhuri Seeds Private Limited, our passion for agriculture drives us to be a leading provider of premium quality seeds for cotton, paddy, and maize.</p>     
        </div>

        <div className='col-md-4 text-center mb-3'>
          <h5 className='mb-4'>Useful Links</h5>
          <li style={{listStyleType:'none',marginTop:'5px'}}><Link to='Cotton' style={{color:'white'}}>Cotton</Link></li>
          <li style={{listStyleType:'none',marginTop:'5px'}}><Link to='Paddy' style={{color:'white'}}>Paddy</Link></li>
          <li style={{listStyleType:'none',marginTop:'5px'}}><Link to='Maize' style={{color:'white'}}>Maize</Link></li>
          
        </div>
        <div className='col-md-4 text-center'>
          <h5 className='mb-4'>Contact Us</h5>
          <div className=''>
          <i className="bi bi-house-door-fill"></i><span style={{textAlign:'justify',fontSize:'small'}}> Plot No.8, Laxma Reddy Palem, Near Main Road, Peddamberpet(v),Abdullapurmet(M),Rangareddy(Dist.) Telangana State,-501505</span>
          </div>
          <div className='mt-2'>
          <i className="bi bi-envelope-at-fill" style={{fontSize:'medium'}}> sindhuriseeds999@gmail.com</i>
          </div>
          <div className='mt-2'>
          <i className="bi bi-telephone-fill"> 9440 708 632</i>
          </div>
          
        </div>

    
      </div>
    </div>
    
  </footer>
 
    </div>
    </>
  )
}

export default Footer