
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { CiInstagram } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";
import { IoLogoGoogle } from "react-icons/io5";
import { CgMail } from "react-icons/cg";
import { CiMobile1 } from "react-icons/ci";
import { RiMenuLine } from "react-icons/ri";
import React, { useEffect, useState, useRef } from "react";
import { AiFillInstagram } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa"

const Header = () => {
    const [menu, setMenu] = useState(false)
    const [Aboutmenu, setAboutMenu] = useState(false)
    const [Productsmenu, setProductsMenu] = useState(false)
    const [Mediamenu, setMediaMenu] = useState(false)

    const DisplayItems = () => {
        setMenu(!menu)
    }
    const DisplayAboutmenu = () => {
        setAboutMenu(true)
    }
    const NotDisplayAboutmenu = () => {
        setAboutMenu(false)
    }
    const DisplayProductsmenu = () => {
        setProductsMenu(true)
        setAboutMenu(false)
    }
    const NotDisplayProductsmenu = () => {
        setProductsMenu(false)
    }
    const DisplayMediamenu = () => {
        setMediaMenu(true)
    }
    const NotDisplayMediamenu = () => {
        setMediaMenu(false)
    }


    return (
        <>
            <section className='fixed-top' style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>

                <div className='linked' >

                    <div className='container'>
                        <div className='Social'>
                            <div className='row text-center p-2'>

                                <ul className='col-md-4 col-sm-12 mailContact'>
                                    <li >
                                        <Link style={{ textDecoration: 'none' }} to="/"><CgMail style={{ color: 'white' }} /><span style={{ color: 'black' }}> sindhuriseeds999@gmail.com</span></Link>
                                    </li>
                                </ul>
                                <ul className='col-md-4 col-sm-12 mailContact'>
                                    <li >
                                        <Link style={{ textDecoration: 'none' }} to="tel:9440 708 632"><CiMobile1 style={{ color: 'white',marginBottom:'2.1%' }} /><span style={{ color: 'black' }}> 9440 708 632</span></Link>
                                    </li>
                                </ul>

                                <ul className='col-md-4 col-sm-12 SocialMedia text-center'>
                                    <li>
                                        <Link to="https://www.facebook.com/sindhuriseeds/"><FaFacebookF style={{ color: 'white' }} /></Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.instagram.com/sindhuri_seeds/?next=%2Fdr.srinivaspolisetty%2F"><AiFillInstagram  style={{ color: 'white' }} /></Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.youtube.com/@sindhuriseeds?app=desktop"><FaYoutube style={{ color: 'white' }} /></Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.google.com/search?q=sindhuri+seeds&rlz=1C1JJTC_enIN1028IN1028&oq=&gs_lcrp=EgZjaHJvbWUqBggEEEUYOzIGCAAQRRg8MhIIARAuGEMYgwEYsQMYgAQYigUyGAgCEC4YQxiDARjHARixAxjRAxiABBiKBTIGCAMQRRg5MgYIBBBFGDsyDAgFECMYJxiABBiKBTIGCAYQRRg8MgYIBxBFGDzSAQg0NDc5ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#ip=1"><IoLogoGoogle style={{ color: 'white' }} /></Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <nav style={{ backgroundColor: 'white' }}>
                    <div className='container MainMenu ' >
                        <div className='row'>
                            <div className='col-md-3'>
                                <img className='Logo' src='Untitled-2.png' alt='SindhuriSeeds'></img>

                            </div>


                            <div className='ms-auto col-md-9 links'>
                                <RiMenuLine className='menuBar ' onClick={() => DisplayItems()} />
                                <div className={menu ? 'Mobilenavbar' : 'menuNavbar'}>
                                    <ul className='link-menu' >
                                        <li className='link-menu-list'> <Link to='' className='Link-Items' style={{ color: '#11b247', textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'none', fontSize: 'large', fontFamily: 'AvantGarde Bk BT' }} onMouseOver={() => { setAboutMenu(false); setMediaMenu(false); setProductsMenu(false) }} >Home</Link></li>
                                        <li className='link-menu-list'> <Link to='About_Us' className='Link-Items' style={{ color: '#11b247', textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'none', fontSize: 'large', fontFamily: 'AvantGarde Bk BT' }} onMouseOver={() => { setAboutMenu(false); setMediaMenu(false); setProductsMenu(false) }} >About Us</Link></li>
                                        {/* <li className='mediamenulinks1'><Link style={{ color: '#11b247', textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'none',fontSize: 'large',fontFamily:'AvantGarde Bk BT'  }} className='Link-Items'  onMouseOver={() => { DisplayAboutmenu(); setProductsMenu(false) }} >About Us</Link>
                                            {
                                                Aboutmenu && <ul className='mediamenulinks' onMouseLeave={() => { NotDisplayAboutmenu() }} style={{backgroundColor:'black'}}>
                                                    <li><Link style={{ color: '#11b247',fontFamily:'serif' }} >Link1</Link></li>
                                                    <li><Link style={{ color: '#11b247' }} >Link1</Link></li>
                                                    <li><Link style={{ color: '#11b247' }} >Link1</Link></li>
                                                    <li><Link style={{ color: '#11b247' }} >Link4</Link></li>
                                                    <li><Link style={{ color: '#11b247' }} >Link5</Link></li>
                                                </ul>
                                            }
                                        </li> */}
                                        <li className='link-menu-list mediamenulinks1'><Link style={{ color: '#11b247', textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'none', fontSize: 'large', fontFamily: 'AvantGarde Bk BT' }} className='Link-Items' onMouseOver={() => { DisplayProductsmenu(); setAboutMenu(false); setMediaMenu(false) }} >Our Products</Link>
                                            {
                                                Productsmenu && <div onMouseLeave={() => { NotDisplayProductsmenu() }}>
                                                <div className="row">
                                                    {/* <header>Design Services</header> */}
                                                    <ul className="mediamenulinks11 mega-links col-md-6 col-sm-12">
                                                        <li className='my-2'><Link className='subLinks line' to="" style={{color:'black'}}><b>ORGANIC PRODUCTS</b></Link></li>
                                                        <li className='my-2 mt-3'><Link className='subLinks' to="/FERTI_TOP"><b>FERTI TOP</b></Link></li>
                                                        <li className='my-2'><Link className='subLinks' to="/DHAMAKA_PLUS" ><b>DHAMAKA PLUS</b></Link></li>
                                                        <li className='my-2'><Link className='subLinks' to="/SARAFRI_GOLD"><b>SARAFRI GOLD</b></Link></li>
                                                        <li className='my-2'><Link className='subLinks' to="/SURAKSHA" ><b>SURAKSHA</b></Link></li>
                                                        <li className='my-2'><Link className='subLinks' to="/SARPANCH"><b>SARPANCH</b></Link></li>

                                                    </ul>
                                                
                                                
                                                    {/* <header>Design Services</header> */}
                                                    <ul className="mediamenulinks22 mega-links col-md-6 col-sm-12">
                                                        <li className='my-2'><Link className='subLinks line' to="" style={{color:'black'}}><b>SEEDS</b></Link></li>
                                                        <li className='my-2 mt-3'><Link className='subLinks' to="Cotton"><b>COTTON</b></Link></li>
                                                        <li className='my-2'><Link className='subLinks' to="Paddy" ><b>PADDY</b></Link></li>
                                                        <li className='my-2'><Link className='subLinks' to="Maize" ><b>MAIZE</b></Link></li>
                                                        <li className='my-2'><Link className='subLinks' to="Mirchi" ><b>MIRCHI</b></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                                //<ul className='mediamenulinks ' onMouseLeave={() => { NotDisplayProductsmenu() }} >
                       
                                                //     <li className='mb-2'><Link className='subLinks' to='Cotton' ><b>COTTON</b></Link></li>
                                                    
                                                //     <li className='mb-2'><Link className='subLinks' to="Paddy" ><b>PADDY</b></Link></li>
                                                //     <li className='mb-2'><Link  className='subLinks' to="Maize"><b>MAIZE</b></Link></li>

                                                // </ul>
                                            }
                                        </li>
                                        <li className='link-menu-list'> <Link className='Link-Items' style={{ color: '#11b247', textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'none', fontSize: 'large',fontFamily:'AvantGarde Bk BT'  }}  onMouseOver={() => { setAboutMenu(false); setMediaMenu(false); setProductsMenu(false) }} >Our Presence</Link></li>
                                        <li className='link-menu-list mediamenulinks1' ><Link className='Link-Items' style={{ color: '#11b247', textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'none', fontSize: 'large',fontFamily:'AvantGarde Bk BT'  }}  onMouseOver={() => { DisplayMediamenu() ;setProductsMenu(false)}}>Media</Link>
                                            {
                                                Mediamenu && <ul className='mediamenulinks' onMouseLeave={() => { NotDisplayMediamenu() }}>
                                                    <li className='mb-2'><Link className='subLinks' ><b>IMAGES</b></Link></li>
                                                    <li className='mb-2'><Link className='subLinks' to="Videos"><b>VIDEOS</b></Link></li>
                                                    <li className='mb-2'><Link className='subLinks'><b>BLOGS</b></Link></li>
                                                   
                                                </ul>
                                            }
                                        </li>
                                        <li className='link-menu-list'> <Link className='Link-Items' style={{ color: '#11b247', textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'none', fontSize: 'large',fontFamily:'AvantGarde Bk BT'  }} to='/Contact_Us' onMouseOver={() => { setAboutMenu(false); setMediaMenu(false); setProductsMenu(false) }} >Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </nav>
            </section>

        </>
    )
}

export default Header