import React from 'react'
import { Link } from 'react-router-dom'

const Cotton = () => {
    return (
        <>
            <div style={{ marginTop: '144px' }}></div>
            <div className='backGroundCotton'>
                <div className='text-center'>
                    <div><h3><b>COTTON</b></h3></div>
                </div>
            </div>
            {/* <div className='container'>
                <h3 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI-333</h3>
                <div className='mt-3 row'>
                    <div className='col-md-6 col-sm-12'>
                        <center>
                            <img src='022.jpg' alt='Sindhuri-333' style={{ width: '30%' }}></img>
                        </center>
                    </div>
                    <div className='col-md-6 col-sm-12 mt-4'>
                        <p style={{ textAlign: 'justify' }}>Unleash the potential of high-performance cotton cultivation with Sindhuri Seeds' SINDHURI-333. This cotton variety is known for its exceptional yield, fiber quality, and adaptability to diverse agro-climatic conditions. Farmers can rely on SINDHURI-333 to deliver a robust and profitable cotton harvest, making it a preferred choice for those seeking reliability and excellence.</p>
                        <p className='mb-4'>Sindhuri 333 Cotton Seed - Nurturing Excellence in Every Fiber</p>
                        <Link className='KnowMoreLinks' to="/Sindhuri_333">Know More</Link>
                    </div>
                </div>
                <h3 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>BAADSHA BG II</h3>
                <div className='mt-3 row'>
                    <div className='col-md-6 col-sm-12 mt-2'>
                        <h6 style={{ textAlign: 'justify' }}>Sindhuri BAADSHA BG II Cotton Seed - Defending Your Crop, Nurturing Prosperity</h6>
                        <p style={{ textAlign: 'justify' }}>Harnessing Innovation for Resilient Cotton Cultivation</p>
                        <p className='mb-4' style={{ textAlign: 'justify' }}>Presenting Sindhuri BAADSHA BG II Cotton Seed - a revolutionary choice for farmers seeking advanced technology in cotton cultivation. Crafted with precision and commitment to excellence, BAADSHA BG II stands as a beacon of defense against bollworm infestations, ensuring not just a crop but a shielded and prosperous harvest.</p>
                        <Link className='KnowMoreLinks' to="/BAADSHA_BG_II">Know More</Link>
                    </div>
                    <div className='col-md-6 col-sm-12 '>
                        <center>
                            <img src='044.jpg' alt='BAADSHA BG II' style={{ width: '30%' }}></img>
                        </center>
                    </div>

                </div>
                <h3 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI-666</h3>
                <div className='mt-3 row'>
                    <div className='col-md-6 col-sm-12 mt-2'>
                        <center>
                            <img src='055.jpg' alt='Sindhuri-666' style={{ width: '30%' }}></img>
                        </center>
                    </div>
                    <div className='col-md-6 col-sm-12 mt-1'>
                        <p style={{ textAlign: 'justify' }}>Experience the next level of cotton farming with SINDHURI-666, a premium cotton variety from Sindhuri Seeds. This hybrid offers a perfect blend of high yield, disease resistance, and fiber strength. SINDHURI-666 is designed to meet the challenges of modern agriculture, ensuring that farmers achieve optimum results and maximize their returns on investment. </p>
                        <p className='mb-4' style={{ textAlign: 'justify' }}>Sindhuri 666 - Unleashing Cotton Excellence</p>
                        <Link className='KnowMoreLinks' to="/SINDHURI_666">Know More</Link>
                    </div>
                </div>
            </div> */}
            <div className='container mt-5'>
            <h2 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>Cotton Products</h2>
                <div className='row mt-5'>
                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded' >
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='022.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI-333</h3>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded' >
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='044.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>BAADSHA BG II</h3>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded'>
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='055.jpg' alt='images' className='imageZoom' style={{ width: '83%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI-666</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded'>
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='aakhil.png' alt='images' className='imageZoom' style={{ width: '100%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>AAKHIL BG II</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cotton