import React from 'react'

const SINDHURI_CHAITRA = () => {
    return (
        <>
            <div style={{ marginTop: '144px' }}></div>
            <div className='backGroundMaize'>
                <div className='text-center'>
                    <div><h3><b>SINDHURI CHAITRA</b></h3></div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <h3 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI CHAITRA</h3>
                    <div className='col-md-7 col-sm-12 mt-3'>
                        <p style={{ textAlign: 'justify' }}>Unleash the Potential of Your Corn Fields with CHAITRA</p>
                        <p style={{ textAlign: 'justify' }}>Introducing SINDHURI CHAITRA Hybrid Corn Seeds – the epitome of agricultural innovation. Specially crafted by Sindhuri Seeds Private Limited, CHAITRA is designed to elevate your corn cultivation, ensuring not just a crop, but a symphony of abundance and excellence.</p>
                        <h5 style={{ textAlign: 'justify', color: '#11b247' }}>Key Features:</h5>
                        <p style={{ textAlign: 'justify' }}><b>Crop Season: </b>Precise Crop Duration</p>
                        <p>CHAITRA Hybrid Corn Seeds come with a precisely optimized crop duration, allowing you to plan and manage your harvest with efficiency. This strategic planning ensures that your corn fields flourish within the specified time frame, maximizing productivity.</p>
                        <p><b>Robust Plant Structure</b></p>
                        <p>Experience the resilience of CHAITRA's robust plant structure. These hybrid corn seeds nurture sturdy and vigorous plants, providing a solid foundation for your corn fields. The result is a thriving crop that stands tall against environmental challenges.</p>
                    </div>
                    <div className='col-md-5 col-sm-12 mt-3'>
                        <center>
                            <img src='066.jpg' alt='SINDHURI CHAITRA' style={{ width: '50%' }}></img>
                        </center>
                    </div>
                </div>
                <p><b>High-Yielding Performance</b></p>
                <p>CHAITRA Hybrid Corn Seeds are engineered for high-yielding performance. Your investment in CHAITRA is an investment in increased productivity and profitability. Expect nothing less than a corn harvest that exceeds your expectations.</p>
                <p><b>Disease Resistance</b></p>
                <p>Fortified with robust disease resistance traits, CHAITRA Hybrid Corn Seeds offer protection against common corn diseases. This built-in resilience minimizes the risk of crop loss and reduces the need for extensive pesticide applications, contributing to sustainable farming practices.</p>
                <p><b>Adaptability to Varied Soil Types</b></p>
                <p>Whether your fields are characterized by the richness of black soil or the unique properties of red soil, CHAITRA Hybrid Corn Seeds exhibit adaptability to diverse soil types. This versatility empowers farmers with the flexibility to cultivate in various environments.</p>
 
                <h5 style={{ textAlign: 'justify', color: '#11b247' }}>Why Choose SINDHURI CHAITRA Hybrid Corn Seeds?</h5>
                <p>Optimized Crop Duration: CHAITRA ensures a precisely optimized crop duration, allowing for efficient harvest planning.</p>
                <p>Robust Plant Structure: Experience the resilience of sturdy and vigorous plants, providing a strong foundation for your corn fields.</p>
                <p>High-Yielding Performance: Engineered for high yields, CHAITRA is your key to increased productivity and enhanced profitability.</p>
                <p>Disease Resistance: Fortified against common corn diseases, CHAITRA minimizes the risk of crop loss and reduces dependence on pesticides.</p>
                <p>Adaptability to Soil Types: Suited for various soil types, CHAITRA provides flexibility for farmers in choosing cultivation environments.</p>
                <p>Elevate Your Corn Cultivation with SINDHURI CHAITRA</p>
                <p>Ready to witness a symphony of innovation and yield in your corn fields? Choose SINDHURI CHAITRA Hybrid Corn Seeds for a superior and sustainable farming experience. Join the community of farmers who trust Sindhuri Seeds for excellence in every seed.</p>
               </div>
        </>
    )
}

export default SINDHURI_CHAITRA