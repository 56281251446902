
import './App.css';
import Header from './Header';
import Contact from './Contact';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Footer from './Footer';
import About_Us from './About_Us';
import Media from './Media';
import Cotton from './Cotton';
import Paddy from './Paddy';
import Maize from './Maize';
import Videos from './Videos';
import Sindhuri_333 from './Sindhuri_333';
import Mirchi from './Mirchi';
import BAADSHA_BG_II from './BAADSHA_BG_II';
import SINDHURI_666 from './SINDHURI_666';
import SINDHURI_CHAITRA from './SINDHURI_CHAITRA';
import Contact_Us from './Contact_Us';
import FERTI_TOP from './FERTI_TOP';
import DHAMAKA_PLUS from './DHAMAKA_PLUS';
import SARAFRI_GOLD from './SARAFRI_GOLD';
import SURAKSHA from './SURAKSHA';
import SARPANCH from './SARPANCH';




function App() {
  return (
    <BrowserRouter>
    <Header/>
      <Routes>
        <Route path="/" element={<Home />}/>
          <Route path='/About_Us' element={<About_Us/>}/>
          <Route path="/Videos" element={<Videos/>}/>
          <Route path="/Contact" element={<Contact/>}/>  
          <Route path="/Cotton" element={<Cotton/>}/>
          <Route path="/Paddy" element={<Paddy />}/>
          <Route path="/Maize" element={<Maize />}/>
          <Route path='/Mirchi' element={<Mirchi/>}/>
          <Route path='/Sindhuri_333' element={<Sindhuri_333/>}/>
          <Route path="/BAADSHA_BG_II" element={<BAADSHA_BG_II/>}/>
          <Route path="/SINDHURI_666" element={<SINDHURI_666/>}/>
          <Route path="/SINDHURI_CHAITRA" element={<SINDHURI_CHAITRA />}/>
          <Route path="/Contact_Us" element={<Contact_Us />}/>
          <Route path="/FERTI_TOP" element={<FERTI_TOP />}/>
          <Route path="/DHAMAKA_PLUS" element={<DHAMAKA_PLUS />}/>
          <Route path="/SARAFRI_GOLD" element={<SARAFRI_GOLD/>}/>
          <Route path="/SURAKSHA" element={<SURAKSHA />}/>
          <Route path="/SARPANCH" element={<SARPANCH />}/>
          <Route path="/" element={<Home />}/>
          <Route path="/" element={<Home />}/>
          <Route path="/" element={<Home />}/>
          <Route path="/" element={<Home />}/>
       
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
