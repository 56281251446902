import React from 'react'

const SINDHURI_666 = () => {
    return (
        <>
            <div style={{ marginTop: '144px' }}></div>
            <div className='backGroundCotton'>
                <div className='text-center'>
                    <center>
                        <div><h3><b>SINDHURI-666</b></h3></div>
                    </center>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <h3 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI-666</h3>
                    <div className='col-md-7 col-sm-12 mt-3'>
                        <p style={{ textAlign: 'justify'}}>Experience the next level of cotton farming with SINDHURI-666, a premium cotton variety from Sindhuri Seeds. This hybrid offers a perfect blend of high yield, disease resistance, and fiber strength. SINDHURI-666 is designed to meet the challenges of modern agriculture, ensuring that farmers achieve optimum results and maximize their returns on investment.</p>
                        <p style={{ textAlign: 'justify'}}>Sindhuri 666 - Unleashing Cotton Excellence</p>
                        <h5 style={{ textAlign: 'justify', color: '#11b247' }}>Key Features:</h5>
                        <p style={{ textAlign: 'justify'}}><b>Crop Season: </b>150 to 170 Days</p>
                        <p style={{ textAlign: 'justify'}}>Sindhuri 666 is designed for success throughout a crop season ranging from 150 to 170 days. This extended period ensures that farmers have ample time to nurture the crop and optimize yield.</p>
                        <p style={{ textAlign: 'justify'}}><b>Serving Size: Large</b></p>
                        <p style={{ textAlign: 'justify'}}>Expect a bountiful harvest with Sindhuri 666, as it comes with a large serving size. This characteristic makes it an ideal choice for farmers seeking higher yields and increased income.</p>


                    </div>
                    <div className='col-md-5 col-sm-12 mt-3'>
                        <center>
                            <img src='055.jpg' alt='Sindhuri-666' style={{ width: '53%' }}></img>
                        </center>
                    </div>
                </div>
                <p style={{ textAlign: 'justify'}}><b>Lightweight Cotton</b></p>
                <p style={{ textAlign: 'justify'}}>Sindhuri 666 produces cotton that is not only large in quantity but also lightweight. The quality of the cotton is impeccable, making it highly desirable for textile production and adding to the overall profitability for farmers.</p>
                <p style={{ textAlign: 'justify'}}><b>Whitefly Resistance</b></p>
                <p style={{ textAlign: 'justify'}}>One of the standout features of Sindhuri 666 is its resistance to whiteflies. The high percentage of leaf mold in this variety acts as a natural deterrent, minimizing the impact of whiteflies on the crop. This not only ensures a healthier crop but also reduces the need for extensive pest control measures.</p>
                <p style={{ textAlign: 'justify'}}><b>Suited for Black and Red Soil</b></p>
                <p style={{ textAlign: 'justify'}}>Sindhuri 666 thrives in a variety of soil conditions. Whether it's the richness of black soil or the distinct characteristics of red soil, this cotton seed is adaptable and performs exceptionally well, providing farmers with flexibility in choosing their cultivation areas.</p>
                 <h5 style={{ textAlign: 'justify', color: '#11b247' }}>Why Choose Sindhuri 666?</h5>
                <p style={{ textAlign: 'justify'}}><b>Extended Crop Season: </b>The prolonged crop season allows for meticulous cultivation and management, ensuring optimal results.</p>
                <p style={{ textAlign: 'justify'}}><b>Large Serving Size: </b>Higher yields translate to more income for farmers, making Sindhuri 666 a lucrative choice.</p>
                <p style={{ textAlign: 'justify'}}><b>Quality Cotton: </b>The lightweight and high-quality cotton produced by Sindhuri 666 meets the standards of the textile industry, adding value to every harvest.</p>
                <p style={{ textAlign: 'justify'}}><b>Whitefly Resistance: </b>With a natural defense mechanism against whiteflies, Sindhuri 666 promotes a healthier crop and reduces the need for extensive pest control.</p>
                <p style={{ textAlign: 'justify'}}><b>Adaptability: </b>Suited for both black and red soil, Sindhuri 666 offers versatility in choosing cultivation areas, making it an ideal choice for a wide range of farmers.</p>
                <p style={{ textAlign: 'justify'}}>Elevate Your Cotton Cultivation with Sindhuri 666</p>
                <p style={{ textAlign: 'justify'}}>Ready to transform your cotton farming experience? Choose Sindhuri 666 for a crop that not only meets but exceeds your expectations. Join the community of farmers who trust Sindhuri Seeds for excellence, innovation, and prosperity.</p>
            </div>
        </>
    )
}

export default SINDHURI_666
