import React from 'react'
import { Link } from 'react-router-dom'


const Maize = () => {
    return (
        <>
            <div style={{ marginTop: '144px' }}></div>
            <div className='backGroundMaize'>
                <div className='text-center'>
                    <div><h3><b>MAIZE</b></h3></div>
                </div>
            </div>
            {/* <div className='container'>
                <h3 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI CHAITRA</h3>
                <div className='mt-3 row'>
                    <div className='col-md-6 col-sm-12'>
                        <center>
                            <img src='066.jpg' alt='SINDHURI CHAITRA' style={{ width: '30%' }}></img>
                        </center>
                    </div>
                    <div className='col-md-6 col-sm-12 mt-2'>
                        <p style={{ textAlign: 'justify' }}>Unleash the Potential of Your Corn Fields with CHAITRA</p>
                        <p className='mb-4' style={{ textAlign: 'justify' }}>Introducing SINDHURI CHAITRA Hybrid Corn Seeds – the epitome of agricultural innovation. Specially crafted by Sindhuri Seeds Private Limited, CHAITRA is designed to elevate your corn cultivation, ensuring not just a crop, but a symphony of abundance and excellence.</p>
                        <Link className='KnowMoreLinks' to="/SINDHURI_CHAITRA">Know More</Link>
                    </div>
                </div>
                <h3 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI AADHYA</h3>
                <div className='mt-3 row'>
                    <div className='col-md-6 col-sm-12 mt-4'>
                        <p style={{ textAlign: 'justify' }}>AADHYA - Your Gateway to Modern Maize Cultivation.</p>
                        <p className='mb-4' style={{ textAlign: 'justify' }}>Welcome to the future of maize farming with Sindhuri AADHYA Hybrid Corn Seeds. Meticulously crafted for excellence, AADHYA represents innovation, high yields, and a pathway to prosperity for farmers. Explore the possibilities and transform your corn fields into a thriving landscape of success.</p>
                        <Link className='KnowMoreLinks'>Know More</Link>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <center>
                            <img src='077.jpg' alt='SINDHURI AADHYA' style={{ width: '30%' }}></img>
                        </center>
                    </div>

                </div>
                <h3 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>SINDHURI MADHUMITA</h3>
                <div className='mt-3 row'>
                    <div className='col-md-6 col-sm-12'>
                        <center>
                            <img src='088.jpg' alt='SINDHURI MADHUMITA' style={{ width: '30%' }}></img>
                        </center>
                    </div>
                    <div className='col-md-6 col-sm-12 mt-1'>
                        <p style={{ textAlign: 'justify' }}>Welcome to a New Era of Maize Farming</p>
                        <p className='mb-4' style={{ textAlign: 'justify' }}>Discover the pinnacle of maize cultivation with SINDHURI's MADHUMITA Hybrid Corn Seeds. Tailored with meticulous precision, MADHUMITA embodies innovation, promises high yields, and guarantees a journey towards prosperity for farmers. Immerse yourself in the potential and witness your corn fields transform into a thriving sanctuary of success.</p>
                        <Link className='KnowMoreLinks'>Know More</Link>
                    </div>
                </div>
            </div> */}
            <div className='container mt-5'>
                <h2 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>Maize Products</h2>
                <div className='row mt-5'>
                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded' >
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='066.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI CHAITRA</h3>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded' >
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='077.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI AADHYA</h3>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 P-5'>
                        <div className='carded'>
                            <div className='card-Body'>
                                <center className='image'>
                                    <img src='088.jpg' alt='images' className='imageZoom' style={{ width: '78%' }}></img>
                                </center>
                                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI MADHUMITA</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Maize