import React from 'react'

const SARAFRI_GOLD = () => {
  return (
    <>
      <div className='margin'>

      </div>
      <div className='backGround'>
        <div className='text-center'>
          <div><h3>ORGANIC PRODUCT</h3></div>
        </div>
      </div>
      <div className='container mt-4'>

        <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}><b>SARAFRI GOLD</b></h3>
        <p className='mt-3 mb-3' style={{ borderTop: '1px solid rgb(234, 234, 234)' }}></p>
        <div className='row mt-5'>
          <div className='col-md-6 col-sm-12'>
            <img src='safariImage.jpg' alt='' style={{ width: '100%' }}></img>
          </div>
          <div className='col-md-6 col-sm-12 mt-2'>
            <center>
              <img src='3.JPG' style={{ width: '45%' }}></img>
            </center>
          </div>
        </div>

      </div>
    </>
  )
}

export default SARAFRI_GOLD