import React from 'react'

const About_Us = () => {
    return (
        <>
            <div className='margin'>

            </div>
            <div className='backGround'>
                <div className='text-center'>
                    <div><h3>SINDHURI  SEEDS PRIVATE  LIMITED</h3></div>
                </div>
            </div>
            <div className='container'>
                <h1 className='text-center mt-5' style={{ color: '#11b247'}} >About Us</h1>
                <div className='container mt-2'>
                    <div className='row'>
                        <div className='col-md-5 col-sm-12'>
                            <img src='Untitled-2.png' alt='' style={{ width: '100%',marginTop:'-5%' }}></img>
                        </div>
                        <div className='col-md-7 col-sm-12'>
                            <p>Our Commitment to Excellence</p>
                            <p><b>Innovation in Agriculture</b></p>
                            <p>Innovation is at the core of our philosophy. We invest heavily in research and development to bring forth cutting-edge seeds that incorporate the latest advancements in agricultural science. By staying ahead of industry trends, we provide farmers with seeds that are not just a product but a solution to the challenges they face in the field.</p>
                            <p><b>Uncompromising Quality</b></p>
                            <p>Quality is non-negotiable at Sindhuri Seeds. Our seeds undergo stringent quality control measures, ensuring that only the best reach the hands of farmers. From germination tests to disease resistance evaluations, each seed is meticulously inspected to meet and exceed the highest industry standards.</p>
                            <p><b>Sustainable Agriculture</b></p>
                            <p>Sustainability is not just a buzzword for us; it's a commitment. We actively promote and practice sustainable agriculture, emphasizing environmentally friendly cultivation methods and reducing the ecological footprint of farming. Our aim is to contribute to a greener and healthier planet while ensuring the prosperity of farming communities.</p>
                         </div>
                         <p style={{ textAlign: 'justify' }}>Sindhuri Seeds Private Limited is a Private incorporated on 31 January 2022. It is classified as Non-govt company and is registered at Registrar of Companies, Vijayawada. Its authorized share capital is Rs. 1,500,000 and its paid up capital is Rs. 100,000. It is inolved in Agricultural and animal husbandry service activities, except veterinary activities.[This class includes specialized activities, on a fee or contract basis, mostly performed on the farm.]Sindhuri Seeds Private Limited's Annual General Meeting (AGM) was last held on N/A and as per records from Ministry of Corporate Affairs (MCA), its balance sheet was last filed on N/A. </p>
                            <p style={{ textAlign: 'justify' }}>Directors of Sindhuri Seeds Private Limited are Bala Saidulu Shaik, Kandimalla Sivanageswara Rao and Adam Shafi Shaik.</p>
       
                    </div>
                </div>
            </div>

            <div className='container mt-4'>

                <h2 className='text-center mb-4 mt-4' style={{ color: '#11b247' }}>Managing Director</h2>
                <div className='row'>

                    <div className='col-md-6 col-sm-12'>
                        <center>
                            <img src='' alt=''></img>
                        </center>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Mr. Shaik Bala Saidulu  is the Managing Director of SINDHURI SEEDS PRIVATE LIMITED. He believes in commitment and hard-work. With good leadership skills, attention to detail, he always strives to bring about development changes for organizational excellence. His insights into the organization's operations make him evaluate all possible opportunities and effectively execute to achieve utmost customer satisfaction.</p>
                        <p style={{ textAlign: 'justify' }}>He oversees the organisation’s overall marketing activities, segment-wise product development and pan-India promotional activities.</p>
                    </div>
                </div>

                <h2 className='text-center mb-2' style={{ color: '#11b247' }}>Director</h2>
                <div className='row mt-5'>
                    <div className='col-md-6 col-sm-12 '>
                        <p style={{ textAlign: 'justify' }}>Mr. Nerella Veera Venkata Anudeep is the  Director at SINDHURI SEEDS PRIVATE LIMITED and has been associated with the company from starting. He oversees and monitors the operational aspects of administration, finance and accounts.
                            While his innovation drives new business platforms, he has contributed significantly in sales and marketing of Sindhuri Seeds Private Limited .
                        </p>

                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <center>
                            <img src='' alt=''></img>
                        </center>
                    </div>
                </div>

            </div>
        </>
    )
}

export default About_Us