import React, { useRef,useState } from 'react'
import emailjs from 'emailjs-com'
import { TbDeviceLandlinePhone } from "react-icons/tb";

const Contact_Us = () => {
    const form=useRef()

    const [data, updateData] = useState({
      Name: "",
      Email: '',
      Number: '',
      Subject: '',
      Message: ''
    })
    const { Name, Email, Number, Subject, Message } = data;
  
    const changeHandler = e => {
      updateData({ ...data, [e.target.name]: [e.target.value] })
      
    }
    const submitHandler = e => {
      e.preventDefault();
  
      emailjs.sendForm('service_pcw3sjs', 'template_tasfw8h', e.target, 'pUJx5DYFuhUae-1JD')
        .then((result) => {
          alert("message Sent Succesfully")
          updateData({
            Name:"",
            Email:"",
            Number:"",
            Subject: '',
             Message: ''
  
          })
          
        }, (error) => {
          console.log(error.text);
        });
        
    }
  
  return (
    <>
      <div className='margin'></div>
      <div className='backGround'>
        <div className='text-center'>
          <div><h3>CONTACT US</h3></div>
        </div>
      </div>
      <div className="container text-center con my-3 mt-5 mb-3" >
        <h3 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "black" }}><b>Don't Hesitate To Contact with Us</b></span>
        </h3>
        <div className="row justify-content-center">
          <div className="card my-5" style={{ width: '18rem', margin: '15px', color: 'white', backgroundColor: '#11b247',border: '4px solid #fff',boxShadow: '0 0 20px #6b6a6a',borderRadius: '10px'  }}>
            <div className="card-body">
              <h5 className="card-title mb-3">Address</h5>
              <p className="card-text">Balaji Nagar,Road No.6,Near Ganesh Temple,Medchal(v),Medchal(M),Medchal-Malkajigiri District-1.</p>
              <a href="/" className="btn card-link"><i style={{ color: 'white' }} className="bi bi-facebook"></i></a>
              <a href="/" className="btn card-link"><i style={{ color: 'white' }} className="bi bi-twitter"></i></a>
            </div>
          </div>
          <div className="card my-5 " style={{ width: '18rem', margin: '15px', backgroundColor: '#11b247', color: 'whitesmoke' ,border: '4px solid #fff',boxShadow: '0 0 20px #6b6a6a',borderRadius: '10px' }}>
            <div className="card-body">
              <h5 className="card-title">Contact Details</h5>
              {/* <p className="card-text" style={{fontSize:'14px'}}><TbDeviceLandlinePhone />  0863 226 5559</p> */}
              <p className="card-text mt-4"style={{fontSize:'14px',marginTop:'-10px'}}>SINDHURI SEEDS PRIVATE LIMITED</p>
              <p className="card-text" style={{fontSize:'14px',marginTop:'-10px'}}>Contact : 9440 708 632</p>
              <p className="card-text" style={{fontSize:'14px',marginTop:'-10px'}}>Email : sindhuriseeds999@gmail.com</p>

              <a href="/" className="btn card-link"><i style={{ color: 'white' }} className="bi bi-youtube"></i></a>
              <a href="/" className="btn card-link"><i style={{ color: 'white' }} className="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div >
        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#11b247" }}>MESSAGE US</span>
        </h1>
        <h3 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "black" }}><b>Drop Us Message For Any Query</b></span>
        </h3>

        {/* form submission .......*/}
        <form ref={form} onSubmit={submitHandler}>
          <div className="container border w-100 m-auto p-3 " style={{borderRadius:'20px'}}  >
            <div className="row" >
              <div className="col-lg-6  my-3 ">
                <div className="form-group ">
                  <input type="text" value={Name} onChange={changeHandler} className="form-control" title='' placeholder="Your Name" name="Name" required= "Username is Required..." message= "Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 my-3 ">
                <div className="form-group">
                  <input type="email" value={Email} onChange={changeHandler} className="form-control" title='' placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
                <div className="form-group">
                  <input type="text" value={Number} onChange={changeHandler} className="form-control" title='' name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
                <div className="form-group">
                  <input type="text" value={Subject} onChange={changeHandler} className="form-control" title='' name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-12 my-3">
                <div className="form-group">
                  <textarea name="Message" value={Message} onChange={changeHandler} className="form-control"  id="message" cols="30" rows="6" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <button type="submit" className="btn " title='' name="contact" style={{backgroundColor:"#11b247",color:"white"}} >Send Message</button>
                <div id="msgSubmit" className="h3 text-center hidden"></div>
                <div className="clearfix"></div>
              </div>

            </div>

          </div>


        </form>

        {/* MAP....... */}

      </div>
    </>
  )
}

export default Contact_Us