import React from 'react'

const Mirchi = () => {
  return (
    <>
       <div style={{marginTop:'144px'}}></div>
            <div className='backGroundMirchi'>
                <div className='text-center'>
                    <div><h3><b>MIRCHI</b></h3></div>
                </div>
            </div>
    </>
  )
}

export default Mirchi