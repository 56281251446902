import React from 'react'

const Videos = () => {
    return (
        <>
            <div className='margin'></div>
            <div className='backGround'>
                <div className='text-center'>
                    <div><h3>SINDHURI  SEEDS PRIVATE  LIMITED</h3></div>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/wCGehZMJ3-Y?si=m8bzGGhzsPmqcXKr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ldH4jFYZE_I?si=0XZ5Iq9LoXvPWEJ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/D5YY-d1HY-g?si=GREFU81XAgaMBtD6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jvnCKQDHwCc?si=sI5OD1B7Cdr6479K" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row '>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/b-6flV9-chs?si=oGSu3KvJ33XdLRT8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>

                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8f4-aETjHrw?si=xrnptuC_U3uzwUvh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>

                    </div>
                    <div className='col-md-3 col-sm-12'>

                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/-ZazEm6G4rI?si=8a9PxdNwe7gWJgAu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>

                    </div>
                    <div className='col-md-3 col-sm-12'>

                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/gr9_-V1g4X4?si=qyHPWr6M9ftbn9Df" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>

                    </div>

                </div>

            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/KK6EwTHJpTs?si=v1-ZRwYuy_56cTDT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/2kcfkYZTDtk?si=slMjwm01F6HG96l4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/VEkwJaQzh5s?si=cpbMPsVdichvBIfP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/x6nJcawfcKc?si=FIDmSQtNd1IHMqhj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/MC1hMJjZFkw?si=xhYGgs5kVMrqn_H9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/XUeoyUKeuyQ?si=bKXN8RF4-wbuLo-f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/fj4QsECaCBw?si=IsWVWTOGYGwMdHqx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/HmrHbcLh4Dk?si=XgT9moeENfdiWqtl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/5F3-BLFOUnw?si=EF_g4j5h45UEy1Wn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/6cWYx6DlgcM?si=CP5xNcdZvnDFE3jV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/PeyUhstbcXk?si=tltwGQXOugkNwEjo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/POwKLO3Zkzc?si=dTZCnXOyex-niKht" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                </div>
            </div>


            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/1zgti1fHm5o?si=Y-sb2RSIGpDiBXDj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/VQz-pOUWrxI?si=TW5yxZnBWmYvfhN6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/sPj8Qz1-M9M?si=OOfoDtJN0NLcN0Zd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/QfjvcBO75Ao?si=T9uSuIoN2OnaHAYD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                </div>
            </div>


            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/iVi5L9y8O9w?si=gfl4rdJ-w7TDOsB7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Iej8q3OWwzQ?si=0AaRtQbJ939fkf_t" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/H8OOY1DPS44?si=hRxL85g-LkVmnc8Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/6CpNg9Rob1k?si=NriMpt87cE0dvDEO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                </div>
            </div>


            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/183Scc8NcF0?si=2XkHi7mknPunTGaR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/83ZjpjtYaZA?si=ZumqEPemECjnT_kr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/_qiboDbCrsE?si=kdD4zJdcL-CQZtFt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/16uJ4drdYes?si=t3TmL_JRinMHOZoF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                </div>
            </div>


            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/h7NODh8dQYs?si=oQKVn1EiHGQ_Y4JN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/e4ZcbRJi4DU?si=YHKBNnTcRbR3CIvv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/bAgpNn7dkDo?si=2Xi2Nc_O9R-RFnE7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/OM32qxenBzE?si=GPaB5GwRTSd6X2rM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/50z20_VQKLE?si=xo2Owh1qkU2HxGai" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/gyVQFDgL5dA?si=nePoY0hUpywfl8cs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/YPj-60XmV9M?si=9NlyKEo3HG1nNhjS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/I4NUfpkyaLc?si=ha-ZTSokBF9D8kXd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>

                    </div>
                </div>
            </div>


            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Videos