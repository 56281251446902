import React from 'react'

const BAADSHA_BG_II = () => {
    return (
        <>
            <div style={{ marginTop: '144px' }}></div>
            <div className='backGroundCotton'>
                <div className='text-center'>
                    <center>
                        <div><h3><b>BAADSHA BG II</b></h3></div>
                    </center>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <h3 className='text-center mb-2 mt-5' style={{ color: '#11b247' }}>BAADSHA BG II</h3>
                    <div className='col-md-7 col-sm-12 mt-3'>
                        <p style={{textAlign:'justify'}}><b>Sindhuri BAADSHA BG II Cotton Seed - Defending Your Crop, Nurturing Prosperity</b></p>
                        <p style={{textAlign:'justify'}}>Harnessing Innovation for Resilient Cotton Cultivation</p>
                        <p style={{textAlign:'justify'}}>Presenting Sindhuri BAADSHA BG II Cotton Seed - a revolutionary choice for farmers seeking advanced technology in cotton cultivation. Crafted with precision and commitment to excellence, BAADSHA BG II stands as a beacon of defense against bollworm infestations, ensuring not just a crop but a shielded and prosperous harvest.</p>
                        <h5 style={{ textAlign: 'justify', color: '#11b247' }}>Key Features:</h5>
                        <p style={{textAlign:'justify'}}><b>Bollgard II Technology</b></p>
                        <p style={{textAlign:'justify'}}>BAADSHA BG II is equipped with Bollgard II technology, a pioneering genetic modification that provides inherent resistance against destructive bollworms. This technology acts as a shield, reducing the impact of bollworm infestations and safeguarding the health of your cotton crop.</p>
                        <p style={{textAlign:'justify'}}><b>Extended Crop Season</b></p>
                        <p style={{textAlign:'justify'}}>Enjoy an extended and optimized crop season with BAADSHA BG II, ensuring that your cotton fields thrive within the specified time frame. This strategic planning enables farmers to maximize yield potential and plan their harvests with precision.</p>

                    </div>
                    <div className='col-md-5 col-sm-12 mt-5 mb-3'>
                        <center>
                            <img src='044.jpg' alt='BAADSHA BG II' style={{ width: '55%' }}></img>
                        </center>
                    </div>
                </div>
                <p style={{textAlign:'justify'}}><b>BAADSHA_BG_II</b></p>
                <p style={{textAlign:'justify'}}>BAADSHA BG II cotton seeds produce not only a yield-resistant crop but also high-quality fiber. The resulting cotton is coveted by the textile industry, contributing to the overall value of your harvest and providing a lucrative market option</p>
                <p style={{textAlign:'justify'}}><b>Environmental Responsibility</b></p>
                <p style={{textAlign:'justify'}}>By choosing BAADSHA BG II, you contribute to sustainable agriculture. The reduced reliance on chemical pesticides, thanks to bollworm resistance, aligns with eco-friendly farming practices, fostering a healthier environment for all.</p>
                <p style={{textAlign:'justify'}}><b>Suited for Diverse Soil Types</b></p>
                <p style={{textAlign:'justify'}}>BAADSHA BG II cotton seed exhibits adaptability to a range of soil types, making it suitable for cultivation in both black soil and red soil. This versatility empowers farmers to choose diverse environments for their cotton fields.</p>
                <h5 style={{ textAlign: 'justify', color: '#11b247' }}>Why Choose Sindhuri BAADSHA BG II Cotton Seed?</h5>
                <p style={{textAlign:'justify'}}><b>Bollgard II Technology: </b>Benefit from advanced genetic modification, providing inherent resistance against bollworms and enhancing crop protection.</p>
                <p style={{textAlign:'justify'}}><b>Extended Crop Season: </b>BAADSHA BG II ensures a prolonged and optimized crop season, allowing for meticulous cultivation planning.</p>
                <p style={{textAlign:'justify'}}><b>High-Quality Fiber: </b>The cotton produced is not just resistant but also of superior quality, meeting the standards of the textile industry.</p>
                <p style={{textAlign:'justify'}}><b>Environmental Responsibility: </b>Contribute to sustainable agriculture practices by reducing the need for chemical pesticides, promoting a healthier environment.</p>
                <p style={{textAlign:'justify'}}><b>Adaptability to Soil Types: </b>Suited for diverse soil types, BAADSHA BG II offers flexibility in choosing cultivation areas.</p>
                <p style={{textAlign:'justify'}}>Shield Your Crop, Nurture Prosperity with BAADSHA BG II</p>
                <p style={{textAlign:'justify'}}>Ready to defend your cotton crop against bollworms and nurture prosperity in your fields? Choose Sindhuri BAADSHA BG II Cotton Seed for a superior and sustainable cotton cultivation experience. Join the community of farmers who trust Sindhuri Seeds for innovation, excellence, and protection.</p>

            </div>
        </>
    )
}

export default BAADSHA_BG_II