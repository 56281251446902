import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import CountUp from 'react-countup'
import { Link } from 'react-router-dom';

import ScrollTrigger from 'react-scroll-trigger';


const Home = () => {

  const [counterstart, setcounterstart] = useState(false);
  return (
    <>
      <div style={{ marginTop: '144px' }}></div>
      <Carousel data-bs-theme="dark" className='dark'>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="MODIFIED1.png"
            alt="Second slide"
          />
          {/* <Carousel.Caption>
            <h5>Second slide label</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item className='image1'>
          <img
            className="d-block w-100"
            src="02.png"
            alt="First slide"

          />
          {/* <Carousel.Caption className='text'>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="MODIFIED2.png"
            alt="Second slide"
          />
          {/* <Carousel.Caption>
            <h5>Second slide label</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        {/* <Carousel.Item>
          <img
            className="d-block w-100"
            src="03.png"
            alt="Third slide"
          />
          {/* <Carousel.Caption>
            <h5>Third slide label</h5>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        {/* </Carousel.Item> */}


      </Carousel>

      <div className='container' >
        <h2 className='text-center mt-5' style={{ color: '#11b247', fontWeight: 'bold', fontSize: '36px' }}  >Welcome to Sindhuri Seeds Private Limited</h2>
        <p className='mt-3' style={{ borderTop: '1px solid rgb(234, 234, 234)' }}></p>
        <p className='mt-3'>At Sindhuri Seeds Private Limited, our passion for agriculture drives us to be a leading provider of premium quality seeds for cotton, paddy, and maize. With a rich legacy of innovation, excellence, and sustainability, we are committed to empowering farmers with the finest seeds that guarantee bountiful harvests and sustainable agricultural practices.</p>

        <p style={{ textAlign: 'justify' }}><b>Innovation in Agriculture</b></p>
        <p style={{ textAlign: 'justify' }}>Innovation is at the core of our philosophy. We invest heavily in research and development to bring forth cutting-edge seeds that incorporate the latest advancements in agricultural science. By staying ahead of industry trends, we provide farmers with seeds that are not just a product but a solution to the challenges they face in the field.</p>
        <p style={{ textAlign: 'justify' }}><b>Uncompromising Quality</b></p>
        <p style={{ textAlign: 'justify' }}>Quality is non-negotiable at Sindhuri Seeds. Our seeds undergo stringent quality control measures, ensuring that only the best reach the hands of farmers. From germination tests to disease resistance evaluations, each seed is meticulously inspected to meet and exceed the highest industry standards.</p>
        <Link style={{ textDecoration: 'none', float: 'right', color: '#11b247' }} to="About_Us">Know More</Link>

        <h2 className='text-center mt-5' style={{ color: '#11b247', fontWeight: 'bold', fontSize: '36px' }}>Seed Products</h2>
        <p className='mt-3' style={{ borderTop: '1px solid rgb(234, 234, 234)' }}></p>
        {/* <center>
        <h2 className='text-center mt-3 mb-4 p-3' style={{ backgroundColor: '#11b247', fontWeight: 'bold',color:'white',borderRadius:'50%',width:'20%'}} >Cotton</h2>
        </center> */}

        {/* cotton products */}

        <div className='row '>
          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded' >
              <div className='card-Body'>
                <center className='image'>
                  <img src='022.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI-333</h3>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded' >
              <div className='card-Body'>
                <center className='image'>
                  <img src='044.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>BAADSHA BG II</h3>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded'>
              <div className='card-Body'>
                <center className='image'>
                  <img src='055.jpg' alt='images' className='imageZoom' style={{ width: '83%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI-666</h3>
              </div>
            </div>
          </div>
        </div>

        {/* corn products */}

        <div className='row mt-5'>
          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded' >
              <div className='card-Body'>
                <center className='image'>
                  <img src='066.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI CHAITRA</h3>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded' >
              <div className='card-Body'>
                <center className='image'>
                  <img src='077.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI AADHYA</h3>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded'>
              <div className='card-Body'>
                <center className='image'>
                  <img src='088.jpg' alt='images' className='imageZoom' style={{ width: '78%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI MADHUMITA</h3>
              </div>
            </div>
          </div>
        </div>

        {/* paddy products */}

        <div className='row mt-5'>
          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded' >
              <div className='card-Body'>
                <center className='image'>
                  <img src='01.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI AVANTIKA</h3>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded' >
              <div className='card-Body'>
                <center className='image'>
                  <img src='09.jpg' alt='images' className='imageZoom' style={{ width: '80%', height: '50%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI CHANDINI</h3>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded'>
              <div className='card-Body'>
                <center className='image'>
                  <img src='1010.jpg' alt='images' className='imageZoom' style={{ width: '78%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SINDHURI SRILEKHA</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <h2 className='text-center mt-5' style={{ color: '#11b247', fontWeight: 'bold', fontSize: '36px' }}  >Organic Products</h2>
        <p className='mt-3 mb-3' style={{ borderTop: '1px solid rgb(234, 234, 234)' }}></p>
        <div className='row '>
          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded' >
              <div className='card-Body'>
                <center className='image'>
                  <img src='1.JPG' alt='images' className='imageZoom' style={{ width: '70%', height: '50%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SARPANCH</h3>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded' >
              <div className='card-Body'>
                <center className='image'>
                  <img src='2.JPG' alt='images' className='imageZoom' style={{ width: '70%', height: '50%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>DHAMAKA PLUS</h3>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 P-5'>
            <div className='carded'>
              <div className='card-Body'>
                <center className='image'>
                  <img src='3.JPG' alt='images' className='imageZoom' style={{ width: '70%' }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SARAFRI GOLD</h3>
              </div>
            </div>
          </div>

          
        </div>
        <div className='row '>
        <div className='col-md-6 col-sm-12 P-5'>
            <div className='carded'>
              <div className='card-Body'>
                <center className='image'>
                  <img src='4.JPG' alt='images' className='imageZoom' style={{width: '50%', height: '30%'  }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>SURAKSHA</h3>
              </div>
            </div>
          </div>

          <div className='col-md-6 col-sm-12 P-5'>
            <div className='carded'>
              <div className='card-Body'>
                <center className='image'>
                  <img src='5.JPG' alt='images' className='imageZoom' style={{ width: '80%', height: '30%'  }}></img>
                </center>
                <h3 className='mt-3 mb-3 text-center' style={{ color: '#11b247' }}>FERTI TOP</h3>
              </div>
            </div>
          </div>
         
        </div>
      </div>

      <div className='container'>
        <h2 className='text-center mt-5' style={{ color: '#11b247', fontWeight: 'bold', fontSize: '36px' }}  >Latest Videos</h2>

        <p className='mt-3' style={{ borderTop: '1px solid rgb(234, 234, 234)' }}></p>
        <div className='row mt-5'>
          <div className='col-md-3 col-sm-12 mb-2'>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/wCGehZMJ3-Y?si=m8bzGGhzsPmqcXKr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
          </div>
          <div className='col-md-3 col-sm-12 mb-2'>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/b-6flV9-chs?si=oGSu3KvJ33XdLRT8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
          </div>
          <div className='col-md-3 col-sm-12 mb-2'>

            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8f4-aETjHrw?si=xrnptuC_U3uzwUvh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>

          </div>
          <div className='col-md-3 col-sm-12 mb-2'>

            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/-ZazEm6G4rI?si=8a9PxdNwe7gWJgAu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>

          </div>

        </div>
      </div>
      <div className='container mt-5'>
        <div className='row '>
          <div className='col-md-3 col-sm-12 mb-2'>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/b-6flV9-chs?si=oGSu3KvJ33XdLRT8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
          </div>
          <div className='col-md-3 col-sm-12 mb-2'>

            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8f4-aETjHrw?si=xrnptuC_U3uzwUvh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>

          </div>
          <div className='col-md-3 col-sm-12 mb-2'>

            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/-ZazEm6G4rI?si=8a9PxdNwe7gWJgAu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>

          </div>
          <div className='col-md-3 col-sm-12 mb-2'>

            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/gr9_-V1g4X4?si=qyHPWr6M9ftbn9Df" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }} ></iframe>

          </div>

        </div>

      </div>

      <div className='container'>
        <h2 className='text-center mt-5' style={{ color: '#11b247', fontWeight: 'bold', fontSize: '36px' }} >Success Counter</h2>
        <p className='mt-3' style={{ borderTop: '1px solid rgb(234, 234, 234)' }}></p>
        <div className="row mt-5" >
          <div className="col-md-3 p-3">
            <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
              <div className="Counter card p-3 text-center">
                <h2 className='value'>
                  {counterstart && <CountUp start={0} end={500} duration={2} delay={0} />}
                </h2>
                <h5>+Team Member</h5>
              </div>
            </ScrollTrigger>
          </div>
          <div className="col-md-3 p-3">
            <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
              <div className="Counter card p-3 text-center">
                <h2 className='value'>
                  {counterstart && <CountUp start={0} end={6000} duration={2} delay={0} />}
                </h2>
                <h5>+Satisfied Farmers</h5>
              </div>
            </ScrollTrigger>
          </div>

          <div className="col-md-3 p-3">
            <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
              <div className="Counter card p-3 text-center">
                <h2 className='value'>
                  {counterstart && <CountUp start={0} end={60} duration={2} delay={0} />}
                </h2>
                <h5>+Quality Check</h5>
              </div>
            </ScrollTrigger>
          </div>


          <div className="col-md-3 p-3">
            <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
              <div className="Counter card p-3 text-center">
                <h2 className='value'>
                  {counterstart && <CountUp start={0} end={50} duration={2} delay={0} />}
                </h2>
                <h5>+Product Varieties</h5>
              </div>
            </ScrollTrigger>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home